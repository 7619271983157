import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { CalendarApi } from "../../axios/axios";

export default async function addNotifications() {
  const platform = Capacitor.getPlatform();
  const date = new Date();

  if (platform !== "android" && platform !== "ios") return;

  let permissions = await LocalNotifications.checkPermissions();

  if (permissions.display !== "granted") {
    permissions = await LocalNotifications.requestPermissions();

    if (permissions.display !== "granted") return;
  }

  const notifications = await LocalNotifications.getPending();

  if (notifications.notifications.length) {
    // await LocalNotifications.cancel({
    //   notifications: notifications.notifications.map((e) => ({ id: e.id })),
    // });
    // return;
  }

  const res = (await CalendarApi.calendarAppointmentsPerDay({
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  })) as any;

  if (
    !res ||
    !res.data ||
    !res.data.success ||
    !res.data.data ||
    !res.data.data.calendar ||
    !res.data.data.calendar.length
  )
    return;

  const appointments = res.data.data;

  //let indexAppointment = 0;

  appointments.calendar.forEach(async (e: any) => {
    // If the notification is already scheduled, we skip it
    if (
      notifications?.notifications.some(
        (notification) => notification.id === e.id
      )
    )
      return;

    const isValid = /^(0?[0-9]|1[0-9]|2[0-3])(:|\.)(0?[0-9]|[1-5][0-9])$/.test(
      e.IN01_ORARIOAPPUNTAMENTO
    );
    //indexAppointment++;

    // We process only the notification with an explicit time, not a generic one
    if (!isValid) return;

    const [hour, minutes] = e.IN01_ORARIOAPPUNTAMENTO.split(/:|\./);
    const currentHour = date.getHours();
    const currentMinutes = date.getMinutes();

    // We process only future notifications, not past ones
    if (
      parseInt(hour) < currentHour ||
      (parseInt(hour) === currentHour && parseInt(minutes) <= currentMinutes)
    )
      return;

    const calculatedDate = Date.parse(
      date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        "T" +
        hour.padStart(2, "0") +
        ":" +
        minutes.padStart(2, "0") +
        ":00.000Z"
    );
    const difference =
      Math.floor(calculatedDate / 1000) +
      date.getTimezoneOffset() * 60 -
      Math.floor(Date.now() / 1000);

    // We process only appointments that are at least 10 minutes from now
    if (difference < 600) return;

    const scheduledTime = difference >= 1800 ? 1800 : 600;

    await LocalNotifications.schedule({
      notifications: [
        {
          id: e.id,
          title: "Promemoria: " + e.type,
          body:
            "Ore " +
            e.IN01_ORARIOAPPUNTAMENTO +
            " - " +
            appointments.users[appointments.contracts[e.IN01_CODCF]]
              .AN01_INDIRIZZO,
          largeBody:
            "Ore: " +
            e.IN01_ORARIOAPPUNTAMENTO +
            "\nCliente: " +
            appointments.users[appointments.contracts[e.IN01_CODCF]]
              .AN01_RAGSOANAG +
            "\nPresso: " +
            appointments.users[appointments.contracts[e.IN01_CODCF]]
              .AN01_INDIRIZZO,
          sound: platform === "android" ? "beep.wav" : "bell.mp3",
          schedule: {
            //at: new Date(date.getTime() + 5 * indexAppointment * 1000),
            at: new Date(calculatedDate + (date.getTimezoneOffset() * 60 - scheduledTime) * 1000),
            allowWhileIdle: true,
          },
        },
      ],
    });
  });
}
